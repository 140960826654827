var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "df_ac" },
        [
          _c("el-date-picker", {
            staticStyle: { width: "210px" },
            attrs: {
              size: "mini",
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
            },
            on: { change: _vm.handleDateChange },
            model: {
              value: _vm.dateRange,
              callback: function ($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange",
            },
          }),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.tableData.length > 0
          ? _c(
              "div",
              { ref: "capture", staticClass: "rank_bg" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.generatePictures,
                        expression: "!generatePictures",
                      },
                    ],
                    staticClass: "fix_btn",
                    on: { click: _vm.html2canvas },
                  },
                  [_vm._v("生成图片")]
                ),
                _c("img", {
                  staticClass: "tle",
                  attrs: {
                    src: "https://oss.71go.com/oa0513/lhb.png",
                    alt: "",
                  },
                }),
                _c("transition", { attrs: { name: "fade" } }, [
                  _c("div", { staticClass: "rank_ten" }, [
                    _c("div", { staticClass: "rt_top" }, [
                      _c("div", { staticClass: "rtt_two" }, [
                        _c("div", { staticClass: "rtt_head" }),
                        _c("img", {
                          staticClass: "rtt_img",
                          attrs: {
                            src: _vm.firstThreeItems[1].avatar,
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "rrt_info" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.firstThreeItems[1].salesmanName)),
                          ]),
                          _c("div", { staticClass: "data" }, [
                            _vm._v(
                              "目标：" +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    _vm.firstThreeItems[1].monthlyTarget
                                  )
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              "有效业绩：" +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    _vm.firstThreeItems[1].effectivePrice
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "rtt_one" }, [
                        _c("div", { staticClass: "rtt_head" }),
                        _c("img", {
                          staticClass: "rtt_img",
                          attrs: {
                            src: _vm.firstThreeItems[0].avatar,
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "rrt_info" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.firstThreeItems[0].salesmanName)),
                          ]),
                          _c("div", { staticClass: "data" }, [
                            _vm._v(
                              "目标：" +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    _vm.firstThreeItems[0].monthlyTarget
                                  )
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              "有效业绩：" +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    _vm.firstThreeItems[0].effectivePrice
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "rtt_three" }, [
                        _c("div", { staticClass: "rtt_head" }),
                        _c("img", {
                          staticClass: "rtt_img",
                          attrs: {
                            src: _vm.firstThreeItems[2].avatar,
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "rrt_info" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.firstThreeItems[2].salesmanName)),
                          ]),
                          _c("div", { staticClass: "data" }, [
                            _vm._v(
                              "目标：" +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    _vm.firstThreeItems[2].monthlyTarget
                                  )
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              "有效业绩：" +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    _vm.firstThreeItems[2].effectivePrice
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "rt_bottom" },
                      _vm._l(_vm.itemsFromToTen, function (item) {
                        return _c(
                          "div",
                          { key: item.salesmanId, staticClass: "rtb_item" },
                          [
                            _c("div", { staticClass: "num_bg" }, [
                              _vm._v(_vm._s(item.groupRanking)),
                            ]),
                            _c("div", { staticClass: "rtt_head" }),
                            _c("img", {
                              staticClass: "rtt_img",
                              attrs: { src: item.avatar, alt: "" },
                            }),
                            _c("div", { staticClass: "rrt_info" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.salesmanName)),
                              ]),
                              _c("div", { staticClass: "data" }, [
                                _vm._v(
                                  "目标：" +
                                    _vm._s(
                                      _vm._f("numberWithCommas")(
                                        item.monthlyTarget
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  "有效业绩：" +
                                    _vm._s(
                                      _vm._f("numberWithCommas")(
                                        item.effectivePrice
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  "距上位业绩：" +
                                    _vm._s(
                                      _vm._f("numberWithCommas")(
                                        item.previousDifference
                                      )
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "tr_table" },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.itemsFromToEnd,
                              tripe: "",
                              height: _vm.zoomHeight,
                              "header-cell-style": {
                                background: "#f8f8f9",
                                color: "#333333",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "groupRanking",
                                label: "本月排名",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "salesmanName",
                                label: "姓名",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _vm._v(" 姓名"),
                                        _c("br"),
                                        !_vm.generatePictures
                                          ? _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                size: "mini",
                                                placeholder: "请输入搜索姓名",
                                              },
                                              model: {
                                                value: _vm.filterSalesmanName,
                                                callback: function ($$v) {
                                                  _vm.filterSalesmanName = $$v
                                                },
                                                expression:
                                                  "filterSalesmanName",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.salesmanName) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3935066711
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "monthlyTarget",
                                label: "本月目标",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "月有效业绩数据",
                                width: "120",
                                "cell-style": { background: "#f0f8ff" },
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              color: "#ff4545",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("numberWithCommas")(
                                                    parseFloat(
                                                      row.effectivePrice
                                                    ).toFixed(1)
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4076028786
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "200px",
                                align: "center",
                                prop: "effectiveAchievement",
                                label: "本月有效业绩完成率",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-progress", {
                                          attrs: {
                                            "text-inside": true,
                                            format: _vm._format(
                                              (
                                                scope.row.effectiveAchievement *
                                                100
                                              ).toFixed(1)
                                            ),
                                            "stroke-width": 22,
                                            color: "#ff6e22",
                                            percentage: Number(
                                              (
                                                scope.row.effectiveAchievement *
                                                100
                                              ).toFixed(1) > 100
                                                ? 100
                                                : (
                                                    scope.row
                                                      .effectiveAchievement *
                                                    100
                                                  ).toFixed(1)
                                            ),
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3302958244
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "previousDifference",
                                label: "距上位业绩差额",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "departmentName",
                                label: "部门",
                                width: "130",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              size: "mini",
                                              placeholder: "部门",
                                            },
                                            model: {
                                              value: _vm.filterDepartment,
                                              callback: function ($$v) {
                                                _vm.filterDepartment = $$v
                                              },
                                              expression: "filterDepartment",
                                            },
                                          },
                                          _vm._l(
                                            _vm.allDepartment,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.departmentId,
                                                attrs: {
                                                  label: item.departmentName,
                                                  value: item.departmentId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.departmentName) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3574646491
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "zoneName",
                                label: "战区",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "lastMonthRanking",
                                label: "上月排名",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }