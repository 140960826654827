<template>
  <el-row :gutter="10" style="min-width:1200px">

    <el-col :span="6">
      <el-card :body-style="{ padding: '10px' }" class="box-card" header="部门业绩目标">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj3.png" alt=""> 集团有效业绩数据
          <el-button style="margin-left:auto" size="mini" @click="statistics">业绩统计</el-button>

        </div>
        <div class="card_main" v-if="info">
          <el-progress color="#ff4545" class="progress_item" :width="150" :stroke-width="15" type="circle" :percentage="info.effectiveAchievement?Number((info.effectiveAchievement*100).toFixed(1)):0"></el-progress>
          <div class="bc_data_right">
            <div class="bcd_item">
              <div class="bcdi_tle">目标业绩</div>
              <div class="bcdi_num">{{perfmance | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle ">有效业绩</div>
              <div class="bcdi_num hot">{{(info.effectivePrice).toFixed(1) | numberWithCommas}}</div>
            </div>

          </div>
        </div>
      </el-card>
      <el-card :body-style="{ padding: '10px' }" class="box-card" header="退单占比">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj2.png" alt=""> 集团实际业绩数据</div>
        <div class="card_main">
          <div id="refundChart">

          </div>
          <div class="bc_data_right" v-if="info">
            <div class="bcd_item">
              <div class="bcdi_tle">实际业绩</div>
              <div class="bcdi_num hot">{{(info.actualPriceSum).toFixed(1) | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">退单业绩</div>
              <div class="bcdi_num">{{(info.refundPriceSum+info.depositRefundPriceSum).toFixed(1) | numberWithCommas}}</div>
            </div>

          </div>
        </div>
      </el-card>
      <el-card shadow="hover" :body-style="{ padding: '10px' }" class="box-card" header="签单产品占比">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj1.png" alt=""> 签单产品占比（签单占比排名前五产品）</div>
        <div id="prodrate"></div>
      </el-card>

    </el-col>
    <el-col :span="18">

      <el-card :body-style="{ padding: '10px' }" class="main-card" header="业绩看板">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj5.png" alt=""> 签单趋势</div>

        <div class="area_change">
          <el-radio-group size="mini" @input="changeArea" v-model="areaType" style="margin-right: 5px;">
            <el-radio-button label="1">部门</el-radio-button>
            <el-radio-button label="2">战区</el-radio-button>
            <el-radio-button label="3">全年业绩</el-radio-button>
          </el-radio-group>
        </div>
        <div class="data_change">
          <el-radio-group size="mini" @input="changeChartType" v-model="trendData.chartType" style="margin-right: 5px;">
            <el-radio-button :disabled="areaType==3" label="0">日</el-radio-button>
            <el-radio-button :disabled="areaType == 3" label="3">周</el-radio-button>
            <el-radio-button :disabled="areaType==3" label="1">月</el-radio-button>
            <el-radio-button label="2">年</el-radio-button>
          </el-radio-group>
          <el-select v-if="trendData.chartType == 3" size="mini" style="width:100px" v-model="selectedWeek" placeholder="请选择第几周" @change="handleWeekChange">
            <el-option v-for="(week, index) in weeks" :key="index" :label="`第${week.weekIndex}周`" :value="week.weekIndex"></el-option>
          </el-select>
          <el-date-picker v-if="trendData.chartType == 0" size="mini" style="width:210px" v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleDateChange"></el-date-picker>
          <el-select v-if="trendData.chartType == 1" @change="handleMonthChange" size="mini" style="width:100px" v-model="selectedMonth" placeholder="选择月份">
            <el-option v-for="(month, index) in months" :key="index" :label="month" :value="index + 1"></el-option>
          </el-select>
          <el-date-picker :clearable="false" @change="setYearChanged" style="width:100px" size="mini" v-if="trendData.chartType == 2" v-model="setyear" type="year" placeholder="选择年">
          </el-date-picker>
        </div>
        <div id="progress_trend2"></div>
        <div class="sc_prank">
          <div class="scp_item" v-for="(item,index) in personData" :key="index">
            <div class="r_icon" v-if="areaType != 3">
              <img v-if="index == 0" style=" width: 20px;transform: translateY(3px);" src="https://oss.71go.com/oa0513/yj6.png" alt="">
              <img v-else-if="index == 1" style=" width: 20px;transform: translateY(3px);" src="https://oss.71go.com/oa0513/yj7.png" alt="">
              <img v-else-if="index == 2" style=" width: 20px;transform: translateY(3px);" src="https://oss.71go.com/oa0513/yj8.png" alt="">
              <img v-else-if="index == 3" style=" width: 20px;transform: translateY(3px);" src="https://oss.71go.com/oa0513/yj9.png" alt="">
              <div class="sn" v-else>{{index+1 | addLeadingZero}}</div>
            </div>
            <div class="r_name" v-if="areaType==2">{{item.zoneName}}</div>
            <div class="r_name" v-else>{{areaType==3?item.monthTime:item.departmentName}}</div>
            <div class="r_sche">
              <div class="li_main">
                <div> {{(Number(item.effectivePrice).toFixed(1)) | numberWithCommas}}</div>
                <div>
                  {{ (trendData.chartType == 0 ? (item.dailyTarget ) :trendData.chartType == 1 ? (item.monthlyTarget ) :trendData.chartType == 3 ? (item.targetSum) : (item.monthlyTarget )) | numberWithCommas}}
                </div>
              </div>
              <div class="l_inside" :style="{ width: ((item.effectiveAchievement*100).toFixed(1) > 100? 100 :(item.effectiveAchievement*100).toFixed(1)) + '%' }">

              </div>

            </div>
            <div class="r_perf"> {{(item.effectiveAchievement*100).toFixed(1)+ '%'}}</div>
          </div>
          <i class="scp_item" />
          <i class="scp_item" />
          <i class="scp_item" />

        </div>

      </el-card>
    </el-col>

    <el-dialog :visible.sync="dialogVisible" :title="queryData.startTime+'~'+queryData.endTime+'业绩统计'" center width="86%">
      <el-table :row-class-name="customRowClassName" :data="tableData" style="width: 100%">
        <el-table-column align="center" prop="salesmanName" label="部门" width="170">
          <template slot-scope="scope">
            {{scope.row.departmentName}} <span v-if="scope.row.zoneName">{{'('+scope.row.zoneName+')'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="本月目标" width="120">
          <template slot-scope="{ row }">

            {{ parseFloat(row.monthlyTarget).toFixed(1) | numberWithCommas }}
          </template>
        </el-table-column>
        <el-table-column label="本月实际业绩数据" width="120" :cell-style="{ background: '#f0f8ff' }" align="center">
          <el-table-column label="本月实际业绩" width="120">
            <template slot-scope="{ row }">

              {{ parseFloat(row.actualPriceSumMonth).toFixed(1) | numberWithCommas }}

            </template>
          </el-table-column>
          <el-table-column align="center" prop="monthlyAchievement" label="实际业绩完成率">
            <template slot-scope="scope">
              <el-progress :text-inside="true" :format="_format((scope.row.monthlyAchievement * 100).toFixed(1))" :stroke-width="22" color="#ff4545"
                :percentage="Number((scope.row.monthlyAchievement * 100).toFixed(1)>100?100:(scope.row.monthlyAchievement * 100).toFixed(1))"></el-progress>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column label="本月退单业绩" width="120">
          <template slot-scope="{ row }">

            {{ Number(parseFloat(row.refundPriceSumMonth)+parseFloat(row.depositRefundPriceSumMonth)).toFixed(1) | numberWithCommas }}
          </template>
        </el-table-column>
        <el-table-column label="月有效业绩数据" width="120" :cell-style="{ background: '#f0f8ff' }" align="center">
          <el-table-column label="本月有效业绩" width="120">
            <template slot-scope="{ row }">
              <div style="font-weight:bold;color:#ff4545">
                {{ parseFloat(row.effectivePriceMonth).toFixed(1)  | numberWithCommas}}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="effectiveAchievement" label="有效业绩完成率">
            <template slot-scope="scope">
              <el-progress :text-inside="true" :format="_format((scope.row.effectiveAchievementMonth * 100).toFixed(1))" :stroke-width="22" color="#ff4545"
                :percentage="Number((scope.row.effectiveAchievementMonth * 100).toFixed(1)>100?100:(scope.row.effectiveAchievementMonth * 100).toFixed(1))"></el-progress>
            </template>
          </el-table-column>

        </el-table-column>

        <el-table-column label="周有效业绩数据" width="120" :cell-style="{ background: '#f0f8ff' }" align="center" v-if="isMonth">
          <el-table-column label="本周目标" width="120">
            <template slot-scope="{ row }">

              {{ parseFloat(row.weekTarget).toFixed(1) | numberWithCommas }}

            </template>
          </el-table-column>
          <el-table-column label="本周有效业绩" width="120">
            <template slot-scope="{ row }">
              <div style="font-weight:bold;color:#ff8c36">
                {{ parseFloat(row.effectivePriceWeek).toFixed(1) | numberWithCommas }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="effectiveAchievementWeek" label="有效业绩完成率">
            <template slot-scope="scope">
              <el-progress :text-inside="true" :format="_format((scope.row.effectiveAchievementWeek * 100).toFixed(1))" :stroke-width="22" color="#ff4545"
                :percentage="Number((scope.row.effectiveAchievementWeek * 100).toFixed(1)>100?100:(scope.row.effectiveAchievementWeek * 100).toFixed(1))"></el-progress>
            </template>
          </el-table-column>

        </el-table-column>

        <el-table-column label="日有效业绩数据" width="120" :cell-style="{ background: '#f0f8ff' }" align="center" v-if="isMonth">
          <el-table-column label="日销售目标" width="120">
            <template slot-scope="{ row }">
              {{ parseFloat(row.dailyTarget).toFixed(1) | numberWithCommas }}
            </template>
          </el-table-column>
          <el-table-column label="日有效业绩" width="100">
            <template slot-scope="{ row }">
              <div style="font-weight:bold;color:#396ff6">
                {{ parseFloat(row.effectivePriceDay).toFixed(1) | numberWithCommas}}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="effectiveAchievementDay" label="有效业绩完成率">
            <template slot-scope="scope">
              <el-progress :text-inside="true" :format="_format((scope.row.effectiveAchievementDay * 100).toFixed(1))" :stroke-width="22" color="#ff4545"
                :percentage="Number((scope.row.effectiveAchievementDay * 100).toFixed(1)>100?100:(scope.row.effectiveAchievementDay * 100).toFixed(1))"></el-progress>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column align="center" prop="departmentRanking" label="今月排名" width="80"></el-table-column>
        <el-table-column align="center" prop="lastMonthDepartmentRanking" label="上月排名" width="80"></el-table-column>
      </el-table>
    </el-dialog>
  </el-row>
</template>

<script>
import {
  findYearChart,
  findAchievementInfoByDepartment,
  findAchievementInfoByZone,
  findSaleInfo,
  findAchievementInfoSummary,
  findWeekRangeList,
  findTargetInfo,
  findSummaryAchievement,
} from "@/api/analysis/data";
import echarts from "echarts";
export default {
  // Component logic here
  data() {
    return {
      isMonth: true,
      dialogVisible: false,
      TargetInfo: {},
      selectedWeek: null, // 存储选中的周数
      weeks: [], // 存储当前月份的周数
      dateRange: [],
      tableData: [],
      refundOption: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          position: function (pos, params, dom, rect, size) {
            return [pos[0] + 10, pos[1]]; // 向右偏移10像素
          },
        },
        series: [
          {
            name: "退单比例",
            type: "pie",
            selectedMode: "single",
            radius: [0, "40%"],
            label: {
              position: "inner",
              fontSize: 10,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            name: "部门实际业绩",
            type: "pie",
            radius: ["55%", "75%"],
            label: {
              position: "inner",
              fontSize: 12,
              formatter: "{b}: {c}% ",
              alignTo: "none",
              bleedMargin: 5,
            },

            data: [],
          },
        ],
      },
      prodrateOption: {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "签单产品占比",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "65%"],
            padAngle: 5,
            itemStyle: {
              borderRadius: 20,
              borderColor: "#fff",
              borderWidth: 3,
              color: "#999",
            },
            label: {
              color: "#333",
              show: true,
              position: "right",
              formatter: "{b}:\n {c}元", // 显示名称和值，并添加'件'
              itemStyle: {
                borderRadius: 20,
                borderColor: "#fff",
                borderWidth: 3,
                color: "#333",
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 13,
                fontWeight: "bold",
                color: "#333",
              },
            },
            labelLine: {
              show: true,
            },
            data: [],
          },
        ],
      },
      info: null,
      areaType: 1,
      setyear: null,
      selectedMonth: new Date().getMonth() + 1,
      months: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      personData: {},
      queryData: {
        startTime: "",
        endTime: "",
      },
      trendData: {
        chartType: "1",
        startTime: null,
        endTime: null,
      },

      trendOption: {
        tooltip: {},

        legend: {
          data: ["有效业绩", "业绩目标", "业绩完成率"],
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisPointer: {
              type: "none",
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#333", // 设置 y 轴文字颜色为 #333
            },
            axisLine: {
              lineStyle: {
                color: "#eee", // 设置横轴颜色为 #666
              },
            },
            axisTick: {
              lineStyle: {
                color: "#eee", // 设置刻度线颜色为 #666
              },
            },
            nameTextStyle: {
              color: "#666", // 设置 y 轴名称颜色为 #666
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "有效业绩",
            min: 0,
            axisLabel: {
              formatter: "{value} 元",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed", // 设置分隔线为虚线
                opacity: 0.6, // 设置透明度
              },
            },
            axisLabel: {
              color: "#333", // 设置 y 轴文字颜色为 #333
            },
            axisLine: {
              lineStyle: {
                color: "#eee", // 设置横轴颜色为 #666
              },
            },
            axisTick: {
              lineStyle: {
                color: "#eee", // 设置刻度线颜色为 #666
              },
            },
            axisPointer: {
              type: "none",
            },
            nameTextStyle: {
              color: "#666", // 设置 y 轴名称颜色为 #666
            },
          },
          {
            type: "value",
            name: "业绩完成率",
            min: 0,
            axisLabel: {
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#333", // 设置 y 轴文字颜色为 #333
            },
            axisLine: {
              lineStyle: {
                color: "#eee", // 设置横轴颜色为 #666
              },
            },
            axisTick: {
              lineStyle: {
                color: "#eee", // 设置刻度线颜色为 #666
              },
            },
            axisPointer: {
              type: "none",
            },
            nameTextStyle: {
              color: "#666", // 设置 y 轴名称颜色为 #666
            },
          },
        ],
        series: [
          {
            itemStyle: { color: "#ff4545" },
            name: "有效业绩",
            type: "bar",
            barWidth: "15%", // Decrease the width of the bars
            tooltip: {
              valueFormatter: function (value) {
                return value + " 元";
              },
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: "业绩目标",
            type: "bar",
            barGap: "-100%",
            barWidth: "15%", // Decrease the width of the bars
            itemStyle: { color: "#ffa0a0", opacity: "0.5" },
            tooltip: {
              valueFormatter: function (value) {
                return value + "元";
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: "业绩完成率",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " %";
              },
            },
            itemStyle: { color: "#ff6d00" },
            areaStyle: {
              // 使用渐变色设置背景颜色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(255, 69, 69,0.3)" },
                { offset: 1, color: "rgba(255, 69, 69,0)" },
              ]),
            },
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
        ],
      },
    };
  },
  computed: {
    perfmance: function () {
      switch (this.trendData.chartType) {
        case "0":
        case 0:
          return this.TargetInfo.dailyTarget || 0;
          break;
        case "1":
        case 1:
          return this.TargetInfo.monthlyTarget || 0;
          break;
        case "2":
        case 2:
          return this.TargetInfo.yearTarget || 0;
          break;
        case "3":
        case 3:
          return this.TargetInfo.weekTarget || 0;
          break;
        default:
          return 0;
      }
    },
  },
  mounted() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    this.queryData.startTime = `${firstDayOfMonth.getFullYear()}-${(
      firstDayOfMonth.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-01`;
    this.queryData.endTime = `${lastDayOfMonth.getFullYear()}-${(
      lastDayOfMonth.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
    this.setCurrentMonth();
    this.getDetail();
    this.calculateWeeks();
  },
  filters: {
    addLeadingZero(value) {
      if (value < 10) {
        return "0" + value; // Add leading zero if the number is less than 10
      } else {
        return value.toString(); // Convert number to string and return as is if it's 10 or greater
      }
    },
  },
  methods: {
    statistics() {
      // 获取当前日期
      const currentDate = new Date();

      // 将 queryData.startTime 转换为 Date 对象
      const startTime = new Date(this.queryData.startTime);

      // 判断 queryData.startTime 是否是当月的日期
      if (
        startTime.getFullYear() === currentDate.getFullYear() &&
        startTime.getMonth() === currentDate.getMonth()
      ) {
        this.isMonth = true;
      } else {
        this.isMonth = false;
      }
      this.$emit("addPoint", "点击集团业绩统计");
      this.getTableData();
      this.dialogVisible = true;
    },
    async getTargetInfo() {
      let res = await findTargetInfo(this.queryData);
      if (res.code == 200) {
        this.TargetInfo = res.data;
      }
    },
    handleDateChange(value) {
      if (value && value.length === 2) {
        this.queryData.startTime = this.formatDate(value[0]);
        this.queryData.endTime = this.formatDate(value[1]);
        this.getDetail();
      }
    },
    _format(value) {
      return () => {
        return value + "%";
      };
    },
    customRowClassName({ row, rowIndex }) {
      return rowIndex === this.tableData.length - 1 ? "last-row" : "";
    },
    async getTableData() {
      let res = await findAchievementInfoSummary(this.queryData);
      if (res.code == 200) {
        console.log(res);
        this.tableData = res.data;
        this.tableData.push({
          departmentName: "集团总业绩",
          monthlyAchievement: this.info.monthlyAchievement,
          monthlyTarget: this.info.monthlyTarget,
          actualPriceSumMonth: this.tableData.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue.actualPriceSumMonth;
            },
            0
          ),
          effectivePriceMonth: res.data.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.effectivePriceMonth;
          }, 0),
          dailyTarget: this.info.dailyTarget,
          effectivePriceDay: res.data.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.effectivePriceDay;
          }, 0),
          refundPriceSumMonth: this.info.refundPriceSum,
          depositRefundPriceSumMonth: this.info.depositRefundPriceSum,
          effectiveAchievementWeek:
            res.data.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.effectivePriceWeek;
            }, 0) / this.TargetInfo.weekTarget,
          effectiveAchievementDay:
            res.data.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.effectivePriceDay;
            }, 0) / this.info.dailyTarget,
          effectiveAchievementMonth: this.info.effectiveAchievement,
          departmentRanking: "-",
          lastMonthDepartmentRanking: "-",
          weekTarget: this.TargetInfo.weekTarget,
          effectivePriceWeek: res.data.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.effectivePriceWeek;
          }, 0),
        });
      }
    },
    initrefundChart() {
      var refundDom = document.getElementById("refundChart");
      var refund = echarts.init(refundDom);
      refund.setOption(this.refundOption);
    },
    async getSaleInfo() {
      const colors = [
        "#817fff",
        "#4184f2",
        "#17a3ff",
        "#fac74f",
        "#7d82b8",
        "#5f6cad",
        "#4394e1",
        "#2fc6d7",
        "#88a0d2",
        "#6bb3cc",
        "#4dc6c6",
        "#30d9bc",
        "#f5c272",
        "#f4d491",
        "#f6e7b4",
        "#9977ad",
        "#b28fb3",
        "#c8a7b8",
        "#dfbfdc",
        "#86a873",
        "#a6c187",
      ];
      let res = await findSaleInfo(this.queryData);
      console.log(res);
      if (res.code == 200) {
        let arr = res.data.map((item, index) => {
          return {
            value: item.actualPriceSum,
            itemStyle: { color: colors[index] },
            name: item.classifyNameTwo,
          };
        });
        this.prodrateOption.series[0].data = arr;
        this.initChart();
      }
    },
    initChart() {
      var chartDom = document.getElementById("prodrate");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.prodrateOption);
    },
    async getInfo() {
      let res;
      if (this.trendData.chartType == 2) {
        res = await findSummaryAchievement(this.queryData);
      } else {
        res = await findYearChart(this.queryData);
      }

      if (res.code == 200) {
        this.info = this.trendData.chartType == 2 ? res.data : res.data[0];
        this.refundOption.series[0].data = [
          {
            value: this.info.depositRefundPriceSum,
            name: "预存款",
            itemStyle: { color: "#ffa0a0" },
          },
          {
            value: this.info.refundPriceSum,
            name: "退款",
            itemStyle: { color: "#ff4b4b" },
          },
        ];
        this.refundOption.series[1].data = [
          {
            value: Number((this.info.monthlyAchievement * 100).toFixed(1)),
            name: "完成",
            itemStyle: { color: "#ff4b4b" },
          },
          {
            value: Number(
              (100 - this.info.monthlyAchievement * 100).toFixed(1)
            ),
            name: "未完成",
            itemStyle: { color: "#ffa0a0" },
            label: {
              show: false, // 设置为true以显示label
            },
          },
        ];
        this.initrefundChart();
      }
    },
    changeArea(value) {
      if (value == 1) {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );

        this.queryData.startTime = `${firstDayOfMonth.getFullYear()}-${(
          firstDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-01`;
        this.queryData.endTime = `${lastDayOfMonth.getFullYear()}-${(
          lastDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
        this.trendData.chartType = 1;
        this.getDetail();
      } else if (value == 2) {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );

        this.queryData.startTime = `${firstDayOfMonth.getFullYear()}-${(
          firstDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-01`;
        this.queryData.endTime = `${lastDayOfMonth.getFullYear()}-${(
          lastDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
        this.trendData.chartType = 1;
        this.getAreaDetail();
      } else if (value == 3) {
        this.setyear = new Date();
        this.trendData.chartType = 2;
        const selectedYear = new Date().getFullYear();
        const firstDayOfYear = `${selectedYear}-01-01`;
        const lastDayOfYear = `${selectedYear}-12-31`;
        this.queryData.startTime = firstDayOfYear;
        this.queryData.endTime = lastDayOfYear;
        this.getYearDetail();
      }
    },
    async calculateWeeks() {
      let res = await findWeekRangeList({
        monthTime: this.queryData.startTime.slice(0, 7),
      });
      this.weeks = res.data;
    },
    setDefaultWeek() {
      const today = new Date();
      const currentDay = today.getDate();
      const currentMonth = today.getMonth();
      const firstDayOfMonth = new Date(today.getFullYear(), currentMonth, 1);

      let firstDayWeekday = firstDayOfMonth.getDay(); // 获取当月第一天是星期几

      let currentWeek = Math.ceil((currentDay + firstDayWeekday) / 7); // 计算当前日期所在的周数
      console.log(this.weeks[currentWeek - 1]);
      this.selectedWeek = currentWeek; // 设置默认选中的周数
    },
    handleWeekChange(va) {
      this.queryData.startTime = this.weeks[this.selectedWeek - 1].startTime;
      this.queryData.endTime = this.weeks[this.selectedWeek - 1].endTime;
      this.getDetail();
    },
    changeChartType(value) {
      if (value == 1) {
        // 本月
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        this.selectedMonth = this.months[currentDate.getMonth()];
        this.queryData.startTime = `${firstDayOfMonth.getFullYear()}-${(
          firstDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-01`;
        this.queryData.endTime = `${lastDayOfMonth.getFullYear()}-${(
          lastDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
      } else if (value == 2) {
        // 本年
        const currentYear = new Date().getFullYear();
        this.queryData.startTime = `${currentYear}-01-01`;
        this.queryData.endTime = `${currentYear}-12-31`;
        this.setyear = new Date();
      } else if (value == 0) {
        const today = new Date().toISOString().split("T")[0];
        this.dateRange = [new Date(), new Date()];
        this.queryData.startTime = today;
        this.queryData.endTime = today;
      } else if (value == 3) {
        let currentDate = new Date(); // 获取当前日期
        let currentDay = currentDate.getDay(); // 获取今天是星期几（0表示星期日，1表示星期一，以此类推）

        // 计算本周一的日期
        let monday = new Date(currentDate);
        monday.setDate(monday.getDate() - currentDay + 1); // 本周一的日期

        // 计算本周日的日期
        let sunday = new Date(currentDate);
        sunday.setDate(sunday.getDate() - currentDay + 7); // 本周日的日期

        this.queryData.startTime = this.formatDate(monday); // 将本周一的日期赋给 startTime
        this.queryData.endTime = this.formatDate(sunday); // 将本周日的日期赋给 endTime
        this.setDefaultWeek();
      }
      if (this.areaType == 1) {
        this.getDetail();
      } else {
        this.getAreaDetail();
      }
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async getYearDetail() {
      let res = await findYearChart(this.queryData);
      if (res.code == 200) {
        this.personData = res.data;
        let personName = res.data.map((item) => item.monthTime);
        let actualPerformance = res.data.map((item) => {
          let actualPrice = Number(item.effectivePrice).toFixed(1);
          return actualPrice;
        });
        let monthlyTarget = res.data.map((item) => item.monthlyTarget);
        let monthlyAchievement = res.data.map((item) => {
          let Achievement = item.effectiveAchievement * 100;
          return Achievement.toFixed(1);
        });
        console.log("actualPerformance", actualPerformance);
        console.log("monthlyTarget", monthlyTarget);
        console.log("monthlyAchievement", monthlyAchievement);
        this.trendOption.xAxis[0].data = personName;
        this.trendOption.series[0].data = actualPerformance;
        this.trendOption.series[1].data = monthlyTarget;
        this.trendOption.series[2].data = monthlyAchievement;

        this.initTrendChart();
      }
    },
    setYearChanged(value) {
      const selectedYear = new Date(value).getFullYear();
      const firstDayOfYear = `${selectedYear}-01-01`;
      const lastDayOfYear = `${selectedYear}-12-31`;
      this.queryData.startTime = firstDayOfYear;
      this.queryData.endTime = lastDayOfYear;
      if (this.areaType == 1) {
        this.getDetail();
      } else if (this.areaType == 2) {
        this.getAreaDetail();
      } else if (this.areaType == 3) {
        this.getYearDetail();
      }
    },
    setCurrentMonth() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;

      // Set the start time to the first day of the current month
      this.queryData.startTime = `${year}-${month
        .toString()
        .padStart(2, "0")}-01`;

      // Calculate the last day of the current month
      const lastDay = new Date(year, month, 0).getDate();
      this.queryData.endTime = `${year}-${month
        .toString()
        .padStart(2, "0")}-${lastDay.toString().padStart(2, "0")}`;
    },
    handleMonthChange() {
      const year = new Date().getFullYear();
      const month = this.selectedMonth;
      const firstDayOfMonth = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDayOfMonth = `${year}-${month
        .toString()
        .padStart(2, "0")}-${new Date(year, month, 0).getDate()}`;

      this.queryData.startTime = firstDayOfMonth;
      this.queryData.endTime = lastDayOfMonth;

      this.getDetail(); // 调用 getDetail 方法
    },
    initTrendChart() {
      var chartDom2 = document.getElementById("progress_trend2");
      var myChart2 = echarts.init(chartDom2);
      myChart2.setOption(this.trendOption);
    },
    async getDetail() {
      let res = await findAchievementInfoByDepartment(this.queryData);
      console.log(res);
      if (res.code == 200) {
        this.personData = res.data;
        let personName = res.data.map((item) => item.departmentName);
        let actualPerformance = res.data.map((item) => {
          let actualPrice = Number(item.effectivePrice).toFixed(1);
          return actualPrice;
        });
        let monthlyTarget = res.data.map((item) => {
          if (this.trendData.chartType == 0) {
            return item.dailyTarget;
          } else if (this.trendData.chartType == 1) {
            return item.monthlyTarget;
          } else if (this.trendData.chartType == 3) {
            return item.targetSum;
          } else {
            return item.monthlyTarget; // 处理不明确的 chartType 值
          }
        });
        let monthlyAchievement = res.data.map((item) => {
          let Achievement = item.effectiveAchievement * 100;
          return Achievement.toFixed(1);
        });
        console.log("actualPerformance", actualPerformance);
        console.log("monthlyTarget", monthlyTarget);
        console.log("monthlyAchievement", monthlyAchievement);
        this.trendOption.xAxis[0].data = personName;
        this.trendOption.series[0].data = actualPerformance;
        this.trendOption.series[1].data = monthlyTarget;
        this.trendOption.series[2].data = monthlyAchievement;

        this.initTrendChart();
        this.getInfo();
        this.getSaleInfo();
        this.getTargetInfo();
      }
    },

    async getAreaDetail() {
      let res = await findAchievementInfoByZone(this.queryData);
      console.log(res);
      if (res.code == 200) {
        this.personData = res.data;
        let personName = res.data.map((item) => item.zoneName);
        let actualPerformance = res.data.map((item) => {
          let actualPrice = Number(item.effectivePrice).toFixed(1);
          return actualPrice;
        });
        let monthlyTarget = res.data.map((item) => item.monthlyTarget);
        let monthlyAchievement = res.data.map((item) => {
          let Achievement = item.effectiveAchievement * 100;
          return Achievement.toFixed(1);
        });
        console.log("actualPerformance", actualPerformance);
        console.log("monthlyTarget", monthlyTarget);
        console.log("monthlyAchievement", monthlyAchievement);
        this.trendOption.xAxis[0].data = personName;
        this.trendOption.series[0].data = actualPerformance;
        this.trendOption.series[1].data = monthlyTarget;
        this.trendOption.series[2].data = monthlyAchievement;

        this.initTrendChart();
        this.getInfo();
        this.getSaleInfo();
      }
    },
    async gettrendData() {
      let res = await findChartBySalesmanId(this.trendData);

      if (res.code == 200) {
        console.log(res);
        let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
        switch (this.trendData.chartType) {
          case 0:
            let source = res.data.map((item, indexCheck) => {
              return [week[indexCheck], item.targetSum, item.effectivePrice];
            });
            source.unshift(["target", "业绩目标", "签单业绩"]);

            this.trendOption.dataset.source = source;
            this.initTrendChart();

            break;

          default:
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#refundChart {
  width: 170px;
  height: 150px;
}
.box-card {
  margin: 20px 10px;
  .card_main {
    display: flex;
    .bc_data_right {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      border-left: 1px solid #e6ebf5;
      padding: 10px;
      .bcd_item {
        min-width: 110px;
        margin: 8px;
        .bcdi_tle {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #666666;
        }
        .hot {
          color: #ff3b3b !important;
        }
        .bcdi_num {
          font-family: DIN, DIN;
          font-weight: 500;
          font-size: 22px;
          color: #333333;
          margin-top: 3px;
        }
      }
    }
  }
}
.box-card2 {
  height: 250px;
  margin: 20px 0px;
  margin-right: 10px;
}
.main-card {
  position: relative;
  margin: 20px 0;
}
.progress_item {
  margin: 10px;
}
.progress_con {
  display: flex;
  justify-content: center;
}
#progress_trend2 {
  height: 455px;
  width: 100%;
}
#prodrate {
  height: 190px;
  width: 100%;
}
.data_change {
  position: absolute;
  right: 114px;
  top: 48px;
  z-index: 99;
  display: flex;
  align-items: center;
}
.area_change {
  position: absolute;
  left: 50%;
  margin-left: -80px;
  top: 18px;
  z-index: 99;
  display: flex;
  align-items: center;
  user-select: none;
}

.sc_prank {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px 10px 0px;
  .scp_item {
    display: flex;
    align-items: center;
    margin-top: 25px;
    width: 358px;
    position: relative;
    .r_perf {
      position: absolute;
      top: -25px;
      right: -60px;
      font-size: 15px; /* 0.42rem converted to px and rounded to nearest integer */
      font-family: OPPOSans;
      font-weight: 500;
      color: #333333;
      margin-left: 4px;
      min-width: 120px;
    }
    .r_name {
      font-size: 16px; /* 0.42rem converted to px and rounded to nearest integer */
      font-family: OPPOSans;
      font-weight: 400;
      color: #333333;
      margin-left: 4px;
      width: 82px;
      text-align: left;
    }
    .r_sche {
      width: 250px;
      height: 25px;
      background: #e6ebf5;
      text-align: right;
      position: relative;
      box-sizing: border-box;
      border-radius: 20px;
      overflow: hidden;

      .li_main {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 0 10px;
        color: #fff;
        /* -webkit-text-stroke: 1px white; */
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
          1px 1px 0 #000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
         font-weight: bold;
        letter-spacing:1px;
      }
      .l_inside {
        box-sizing: border-box;
        height: 25px; /* Same as parent element's height */
        background: linear-gradient(to right, #fd8e8e, #ff4545);
        float: left;
        transition: width 0.5s ease-in-out;
        width: 0%; /* Initial width */
        text-align: left;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        color: #fff;
        font-size: 12px; /* 0.35rem converted to px and rounded to nearest integer */
        line-height: 20px;
        border-radius: 20px;
      }
    }
    .r_icon {
      .sn {
        width: 24px;
        height: 27px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(2px);
      }
    }
  }
}

.card_head {
  color: #333;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.person_rank {
  transform: scale(0.85);
  display: flex;
  align-items: center;
  width: 309px;
  height: 50px;
  background: url("https://oss.71go.com/oa0513/yj4.png");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: space-around;
  color: #333333;
  padding-left: 30px;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #ff4545;
  border-color: #ff4545;

  -webkit-box-shadow: -1px 0 0 0 #ff4545;
  box-shadow: -1px 0 0 0 #ff4545;
  color: #fff;
}
::v-deep .el-radio-button__inner:hover {
  color: #ff4545;
}

::v-deep .is-active .el-radio-button__inner:hover {
  color: #fff !important;
}
::v-deep .last-row td:first-child {
  background-color: #ff4545; /* 自定义样式 */
  color: #fff;
}

::v-deep .last-row:hover td:first-child {
  background-color: transparent;
  color: #606266;
}
::v-deep .last-row:hover td {
  background-color: transparent;
  color: #606266;
}
::v-deep .el-progress-bar__innerText {
  color: #fff;
  /* -webkit-text-stroke: 1px white; */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
</style>
<style>
.el-card__header {
  border-bottom: none;
}
</style>